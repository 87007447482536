import {createSlice, ThunkAction} from '@reduxjs/toolkit';
import {
    login as LoginService,
    getLoginGoogle as GetLoginGoogleService,
    loginGoogleCallback as LoginGoogleCallbackService,
    LogoutService, RegisterService
} from '@/services/auth';
import { RootState} from "../store";

const initialState = {
    user: {},
    loading: false,
    isLoggedIn: false,
    isVerified: false,
    isSuccess: false,
    isLoggedOut: false,
    errorMsg: '',
    successMsg: '',
    successMsgEmail: '',
    errors: {}
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authSuccess: (state, action) => {
            state.isLoggedIn = true;
            state.user = action.payload.user;
            state.errorMsg = '';
            state.errors = {};

            localStorage.setItem('authToken', action.payload.token);
            localStorage.setItem('user', JSON.stringify(action.payload.user));
        },
        updateUser: (state, action) => {
            localStorage.setItem('user', JSON.stringify(action.payload));
        },
        authFailure: (state, action) => {
            state.isLoggedIn = false;
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        logoutSuccess: (state) => {
            state.isLoggedIn = false;
            state.isLoggedOut = true;
            state.user = {};

            localStorage.setItem('authToken', '');
            localStorage.setItem('username', '');
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        setSuccessMsg: (state, action) => {
            state.isSuccess = true;
            state.successMsg = action.payload;
        },
        setSuccessMsgEmail: (state, action) => {
            state.isSuccess = true;
            state.successMsgEmail = action.payload;
        },
        setVerified: (state) => {
            state.isVerified = true;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        setVerifiedError: (state, action) => {
            state.isVerified = false;
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        loading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const { authSuccess, updateUser, authFailure, clearErrors, logoutSuccess, setSuccessMsg, setSuccessMsgEmail, getErrors, setVerified, setVerifiedError, loading } = authSlice.actions;
export default authSlice.reducer;

export const login = (username: string, password: string): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    try {
        dispatch(loading('manual'))

        const response = await LoginService(username, password);

        dispatch(authSuccess(response.data.data));
    } catch (error: any) {
        const {errors, message} = error.response.data.error;

        dispatch(authFailure({
            errorMsg: message,
            errors: errors,
        }));
    }

    dispatch(loading(false))
};

export const getLoginGoogle = (): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    try {
        dispatch(loading('google'))

        const response = await GetLoginGoogleService();

        window.location.href = response.data.data.url;
    } catch (error: any) {
        const {errors, message} = error.response.data.error;

        dispatch(authFailure({
            errorMsg: message,
            errors: errors,
        }));
    }

    dispatch(loading(false))
};

export const loginGoogleCallback = (code: string, scope?: string|null, authUser?: string|null, prompt?: string|null): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    try {
        dispatch(loading(true))

        const response = await LoginGoogleCallbackService(code, scope, authUser, prompt);

        dispatch(authSuccess(response.data.data));
    } catch (error: any) {
        const {errors, message} = error.response.data.error;

        dispatch(authFailure({
            errorMsg: message,
            errors: errors,
        }));
    }

    dispatch(loading(false))
};

export const register = (formData: {
    name: string,
    email: string,
    username: string,
    password: string
}): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    try {
        dispatch(loading('manual'))

        const response = await RegisterService(formData);

        dispatch(authSuccess(response.data.data));
    } catch (error: any) {
        const {errors, message} = error.response.data.error;

        dispatch(authFailure({
            errorMsg: message,
            errors: errors,
        }));
    }

    dispatch(loading(false))
};

export const logout = (): ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    try {
        dispatch(loading(true))

        const response = await LogoutService();

        dispatch(logoutSuccess(response.data.data));
    } catch (error: any) {
        dispatch(logoutSuccess());

        const {errors, message} = error.response.data.error;

        dispatch(authFailure({
            errorMsg: message,
            errors: errors,
        }));
    }

    dispatch(loading(false))
};
