import {Button} from '@/components/button';
import {FormLabel} from '@/components/form/formlabel';
import {Input} from '@/components/form/input';
import {Modal} from '@/components/modal';
import {NoSymbolIcon} from '@heroicons/react/24/outline';
import {
    ArrowDownOnSquareIcon,
    DocumentCheckIcon,
} from '@heroicons/react/24/solid';
import {useEffect, useState} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {DashboardCard} from '../dashboard/_card';
import {SectionItem, SectionTabs} from '../_nav/section';
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/components/form/select";
import {useDispatch, useSelector} from "react-redux";
import {FormatCurrency} from "@/routes/root";
import {GetBanks, ShowPendingBalance, ShowSettledBalance, StoreWithdraw} from "@/services/financial";
import {AppDispatch} from "@/root/store";
import {clearErrors} from "@/reducers/auth";

export function OrgCommerceLayout() {
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();

    const pathArray = location.pathname.split('/').slice(2);
    const section = pathArray[0];

    const [withdrawModal, setWithdrawModal] = useState(false);
    const [formData, setFormData] = useState({
        amount: '',
        bank_code: '',
        account_holder_name: '',
        account_number: ''
    });

    const {pendingWallet, settledWallet, withdraw, banks, errors} = useSelector((state: any) => state.financial);

    const setData = (value: string, element: string) => {
        setFormData({...formData, [element]: value})

        dispatch(clearErrors());
    }

    const submit = () => {
        dispatch(StoreWithdraw(formData));
    }

    useEffect(() => {
        dispatch(ShowPendingBalance());
        dispatch(ShowSettledBalance());
        dispatch(GetBanks());
    }, []);

    useEffect(() => {
        if(withdraw) {
            dispatch(ShowSettledBalance());

            setWithdrawModal(false);
        }
    }, [withdraw]);

    return !section ? (
        <Navigate to='payment'/>
    ) : (
        <>
            <Modal
                show={withdrawModal}
                onRequestClose={() => setWithdrawModal(false)}
                width='medium'
                title='Tarik saldo'
                body={
                    <div className='flex flex-col gap-4'>
                        <FormLabel
                            label='Jumlah saldo yang ingin ditarik'
                            helperText='Minimal penarikan Rp100.000'
                            errorText={errors.amount}
                            required
                        >
                            <Input leftDeco='Rp' type='price' onChange={(e) => setData(e.target.value, 'amount')}
                                   required/>
                        </FormLabel>

                        <FormLabel label='Pilih bank' errorText={errors.bank_code} required>
                            <Select onValueChange={(e) => {
                                setData(e, 'bank_code')
                            }
                            }>
                                <SelectTrigger>
                                    <SelectValue defaultValue='others'/>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {
                                            banks.map((item: any, key: string) => (
                                                <SelectItem key={key} value={item.code}>{item.name}</SelectItem>
                                            ))
                                        }
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </FormLabel>

                        <FormLabel label='No. rekening tujuan' errorText={errors.account_number} required>
                            <Input type='text' onChange={(e) => setData(e.target.value, 'account_number')} required/>
                        </FormLabel>

                        <FormLabel label='Nama pemilik rekening' errorText={errors.account_holder_name} required>
                            <Input type='text' onChange={(e) => setData(e.target.value, 'account_holder_name')} required/>
                        </FormLabel>
                    </div>
                }
                footer={
                    <div className='flex justify-end gap-3'>
                        <Button
                            variant='neutral'
                            onClick={() => setWithdrawModal(false)}
                            leftIcon={<NoSymbolIcon/>}
                        >
                            Batal
                        </Button>
                        <Button
                            onClick={submit}
                            leftIcon={<DocumentCheckIcon/>}
                        >
                            Simpan
                        </Button>
                    </div>
                }
            />
            <div className='flex gap-6'>
                <DashboardCard
                    variant='white'
                    name='Saldo penjualan yang belum dapat ditarik'
                    topLabel={{primary: <FormatCurrency amount={pendingWallet.balance}/>}}
                    className='w-[268px]'
                />
                <DashboardCard
                    variant='whiteAlt'
                    name='Saldo penjualan yang dapat ditarik'
                    topLabel={{primary: <FormatCurrency amount={settledWallet.balance}/>}}
                    bottomLabel={{
                        primary: (
                            <Button
                                size='sm'
                                leftIcon={<ArrowDownOnSquareIcon/>}
                                onClick={() => setWithdrawModal(true)}
                            >
                                Tarik saldo
                            </Button>
                        ),
                    }}
                    className='w-[268px]'
                />
            </div>
            <SectionTabs>
                <SectionItem
                    id='payment'
                    name='Riwayat pemasukan'
                    isCurrent={section === 'payment'}
                />
                <SectionItem
                    id='withdrawal'
                    name='Riwayat penarikan'
                    isCurrent={section === 'withdrawal'}
                />
            </SectionTabs>
            <Outlet/>
        </>
    );
}
