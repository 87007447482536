import {Modal} from "@/components/modal";
import {FormLabel} from "@/components/form/formlabel";
import {Input} from "@/components/form/input";
import {Textarea} from "@/components/form/textarea";
import {Button} from "@/components/button";
import {PlusIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {DocumentCheckIcon, TrashIcon} from "@heroicons/react/24/solid";
import {TicketList} from "@/routes/org/event/_ticket/tickets";
import React from "react";
import {StudioList} from "@/routes/org/event/_studio/studios";
import toast from "react-hot-toast";

interface TicketComponentProps {
    ticketModal: { state: boolean; label: string };
    closeModalTicket: () => void;
    errors: {
        name?: string;
        price?: string;
        quantity?: string;
        max_buy?: string;
        start_sell_at?: string;
        end_sell_at?: string;
        description?: string;
        tickets?: string;
        showtimes?: string;
        seat_layout?: string;
    };
    ticketData: any;
    setData: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => void;
    setTicketData: (e: any) => void;
    updateTicket: () => void;
    tickets: object;
    onEditTicketClicked: (ticketId: string) => void;
    onAddTicketClicked: () => void;
    onDeleteTicketClicked: (ticketId: string) => void;
}

export const StudioComponent: React.FC<TicketComponentProps> = ({
                                                                    ticketModal,
                                                                    closeModalTicket,
                                                                    errors,
                                                                    ticketData,
                                                                    setData,
                                                                    updateTicket,
                                                                    tickets,
                                                                    onEditTicketClicked,
                                                                    onAddTicketClicked,
                                                                    onDeleteTicketClicked,
                                                                    setTicketData
                                                                }) => {

    const addShowtime = () => {
        const newShowtime = {
            id: null, // Null indicates it's a new showtime
            value: '',
        };

        setTicketData((prevData: any) => ({
            ...prevData,
            showtimes: [...prevData.showtimes, newShowtime],
        }));
    };

    // Function to update a showtime's value based on its index
    const updateShowtime = (index: number, newValue: any) => {
        setTicketData((prevData: any) => ({
            ...prevData,
            showtimes: prevData.showtimes.map((showtime: any, i: number) =>
                i === index ? {...showtime, value: newValue} : showtime
            ),
        }));
    };

    // Function to remove a showtime based on its index
    const removeShowtime = (index: number) => {
        setTicketData((prevData: any) => ({
            ...prevData,
            showtimes: prevData.showtimes.filter((_: any, i: number) => i !== index),
        }));
    };

    // Add a new row to the seat layout
    const addRow = () => {
        const newRowIndex = ticketData.seat_layout.length;
        const columnCount =
            ticketData.seat_layout.length > 0 ? ticketData.seat_layout[0].length : 2;
        const newRow = Array.from({ length: columnCount }, (_, colIndex) => ({
            id: `${String.fromCharCode(65 + newRowIndex)}${colIndex + 1}`,
            blocked: false,
            reserved: false,
        }));

        let newLayout = [...ticketData.seat_layout, newRow];
        setTicketData({
            ...ticketData,
            seat_layout: newLayout,
        });

        calculateTotalSeats(newLayout)
    };

    // Add a new column to the seat layout
    const addColumn = () => {
        if (ticketData.seat_layout.length === 0) {
            // If no rows exist, create the first row with 2 columns by default
            const newRow = [
                {id: 'A1', blocked: false, reserved: false},
                {id: 'A2', blocked: false, reserved: false},
            ];
            setTicketData({
                ...ticketData,
                seat_layout: [newRow],
            });

            calculateTotalSeats(newRow)
        } else {
            // Add a column to each existing row
            const newLayout = ticketData.seat_layout?.map((row: any, rowIndex: number) => [
                ...row,
                {
                    id: `${String.fromCharCode(65 + rowIndex)}${row.length + 1}`,
                    blocked: false,
                    reserved: false,
                },
            ]);
            setTicketData({
                ...ticketData,
                seat_layout: newLayout,
            });

            calculateTotalSeats(newLayout)
        }

    };


    // Delete the last row
    const deleteRow = () => {
        if (ticketData.seat_layout.length === 0) return; // Do nothing if there are no rows

        const lastRow = ticketData.seat_layout[ticketData.seat_layout.length - 1];
        if (lastRow.some((seat: any) => seat.reserved)) {
            toast.error("Kamu tidak boleh menghapus seat yang sudah dibooking");
            return;
        }

        const newLayout = [...ticketData.seat_layout];
        newLayout.pop(); // Remove the last row
        setTicketData({
            ...ticketData,
            seat_layout: newLayout,
        });

        calculateTotalSeats(newLayout)
    };

    // Delete the last column
    const deleteColumn = () => {
        if (ticketData.seat_layout.length === 0 || ticketData.seat_layout[0].length === 0) return; // Do nothing if no columns exist

        const columnIndex = ticketData.seat_layout[0].length - 1; // Index of the last column
        if (ticketData.seat_layout.some((row: any) => row[columnIndex]?.reserved)) {
            toast.error("Kamu tidak boleh menghapus seat yang sudah dibooking");
            return;
        }

        const newLayout = ticketData.seat_layout?.map((row: any) => row.slice(0, -1)); // Remove the last column
        setTicketData({
            ...ticketData,
            seat_layout: newLayout,
        });

        calculateTotalSeats(newLayout)
    };

    // Toggle block/unblock seat
    const toggleBlockSeat = (rowIndex: number, colIndex: number) => {
        const newLayout = ticketData.seat_layout?.map((row: any, rIdx: number) =>
            row?.map((seat: any, cIdx: number) =>
                rIdx === rowIndex && cIdx === colIndex
                    ? {...seat, blocked: !seat.blocked, reserved: false}
                    : seat
            )
        );
        setTicketData({
            ...ticketData,
            seat_layout: newLayout,
        });
    };

    const calculateTotalSeats = (seatLayout: any) => {
        let value =  seatLayout.reduce((total: any, row: any) => total + row.length, 0);
    };

    return (
        <>
            <Modal
                show={ticketModal.state}
                onRequestClose={closeModalTicket}
                title={ticketModal.label === "edit" ? "Edit studio" : "Tambah studio"}
                body={
                    <>
                        <div className="mb-3">
                            <FormLabel label="Nama studio" errorText={errors.name} required>
                                <Input type="text" value={ticketData.name} onChange={(e) => setData(e, "name")}
                                       required/>
                            </FormLabel>
                        </div>
                        <div className="mb-3 flex justify-stretch gap-2">
                            <FormLabel label="Harga tiket" errorText={errors.price} required>
                                <Input leftDeco="Rp" type="price" value={ticketData.price}
                                       onChange={(e) => setData(e, "price")} required/>
                            </FormLabel>
                            <FormLabel label="Maks. pembelian/user" errorText={errors.max_buy} required>
                                <Input type="number" min={0} value={ticketData.max_buy}
                                       onChange={(e) => setData(e, "max_buy")} required/>
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label="Waktu mulai dijual" errorText={errors.start_sell_at} required>
                                <Input type="datetime-local" value={ticketData.start_sell_at}
                                       onChange={(e) => setData(e, "start_sell_at")} required/>
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label="Waktu berakhir dijual" errorText={errors.end_sell_at} required>
                                <Input type="datetime-local" value={ticketData.end_sell_at}
                                       onChange={(e) => setData(e, "end_sell_at")} required/>
                            </FormLabel>
                        </div>
                        <div className="mb-3 flex flex-col gap-2">
                            <FormLabel label="Jam tayang" errorText={errors.showtimes}
                                       className={'flex flex-col gap-2'} required>
                                {ticketData.showtimes?.map((showtime: any, index: number) => (
                                    <Input type={'datetime-local'} onChange={(e) => updateShowtime(index, e.target.value)}
                                           value={showtime.value}
                                           rightDeco={
                                               index === 0 ? null : (
                                                   <span
                                                       className="cursor-pointer"
                                                       onClick={() => removeShowtime(index)}
                                                   >
                  <TrashIcon className="h-4 w-4 text-danger"/>
                </span>
                                               )
                                           }
                                           required/>
                                ))}
                            </FormLabel>
                            <Button onClick={addShowtime} className={'w-full'} size={'sm'} leftIcon={<PlusIcon/>}>
                                Tambah Jam Tayang
                            </Button>
                        </div>
                        <div className="mb-3">
                            <FormLabel label="Tempat duduk" errorText={errors.seat_layout} required>
                                <div className="overflow-x-auto">
                                    <div className="grid gap-2">
                                        {ticketData.seat_layout?.map((row: any, rowIndex: number) => (
                                            <div key={rowIndex} className="flex gap-2">
                                                {row?.map((seat: any, colIndex: number) => (
                                                    <Button
                                                        key={seat.id}
                                                        onClick={(e: any) => {
                                                            if(!seat.reserved) {
                                                                e.preventDefault();
                                                                toggleBlockSeat(rowIndex, colIndex);
                                                            } else {
                                                                toast.error("Kamu tidak bisa mengubah seat yang sudah dipesan");
                                                            }
                                                        }}
                                                        className={`w-8 h-8 px-2 py-2 border ${
                                                            seat.blocked
                                                                ? 'bg-gray-500 text-white'
                                                                : seat.reserved
                                                                    ? 'bg-green-500 text-white'
                                                                    : 'bg-gray-200'
                                                        }`}
                                                        title={
                                                            seat.blocked
                                                                ? 'Blocked'
                                                                : seat.reserved
                                                                    ? 'Reserved'
                                                                    : 'Available'
                                                        }
                                                    >
                                                        {seat.id}
                                                    </Button>
                                                ))}
                                            </div>
                                        ))}
                                        <div className={'mt-12 text-center'}>
                                            Screen
                                            <div className={'h-4 bg-blue-400'}></div>
                                        </div>
                                    </div>
                                    <div className="flex gap-2 mt-4">
                                        <Button
                                            onClick={addRow}
                                            leftIcon={<PlusIcon />}
                                        >
                                            Row
                                        </Button>
                                        <Button
                                            onClick={addColumn}
                                            leftIcon={<PlusIcon />}
                                        >
                                            Column
                                        </Button>
                                        <Button
                                            onClick={deleteRow}
                                            leftIcon={<TrashIcon />}
                                        >
                                            Row
                                        </Button>
                                        <Button
                                            onClick={deleteColumn}
                                            leftIcon={<TrashIcon />}
                                        >
                                            Column
                                        </Button>
                                    </div>
                                </div>
                            </FormLabel>
                        </div>
                    </>
                }
                footer={
                    <div className="flex justify-end gap-3">
                        <Button variant="neutral" onClick={closeModalTicket} leftIcon={<XMarkIcon/>}>
                            Tutup
                        </Button>
                        <Button onClick={updateTicket} leftIcon={<DocumentCheckIcon/>}>
                            Simpan
                        </Button>
                    </div>
                }
            />

            <StudioList
                data={tickets}
                title={<p className="flex-grow text-base font-medium text-danger">Daftar studio</p>}
                onEditTicketClicked={onEditTicketClicked}
                onDeleteTicketClicked={onDeleteTicketClicked}
                button={
                    <Button size="sm" look="outline" onClick={onAddTicketClicked} leftIcon={<PlusIcon/>}>
                        Tambah studio baru
                    </Button>
                }
            />
            {errors.tickets && <p className="block text-xs text-danger">{errors.tickets}</p>}
        </>
    );
};
