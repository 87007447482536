import { Button } from '@/components/button';
import { DataViewBase } from '@/components/dataview';
import { EventCard } from '@/components/eventcard';
import { Modal } from '@/components/modal';
import { PlusIcon } from '@heroicons/react/24/outline';
import {DocumentCheckIcon, XMarkIcon} from '@heroicons/react/24/solid';
import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {AppDispatch} from "@/root/store";
import {useDispatch, useSelector} from "react-redux";
import {CreateEvent, GetEvents} from "@/services/events";
import {FormLabel} from "@/components/form/formlabel";
import {Input} from "@/components/form/input";
import {clearErrors} from "@/reducers/auth";

export function OrgEvent() {
    const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [newEventModal, setNewEventModal] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const {events, errors, onEventCreated} = useSelector((state: any) => state.event);

  const queryParams = new URLSearchParams(location.search);
  const searchQuery: string = queryParams.get('search') ?? '';

  const ctaButton = (
    <Button onClick={onNewEventClicked} leftIcon={<PlusIcon />}>
      Buat event baru
    </Button>
  );

    const setData = (value: string) => {
        setTitle(value);
        dispatch(clearErrors());
    };

  function onNewEventClicked() {
      setNewEventModal(true);
  }

  function createEvent() {
    dispatch(CreateEvent(title))
  }

  useEffect(() => {
      if(onEventCreated) {
        window.location.href = 'event/' + onEventCreated + '/edit/step-1';
      }
  }, [onEventCreated])

  useEffect(() => {
    dispatch(GetEvents(searchQuery))
  }, [searchQuery]);

  return (
    <>
      <Modal
        show={newEventModal}
        onRequestClose={() => setNewEventModal(false)}
        title='Buat event baru'
        body={
          <>
              <FormLabel label='Nama event' required className='flex-grow' errorText={errors.title}>
                  <Input type='text' value={title} onChange={(e) => setData(e.target.value)} required />
              </FormLabel>
          </>
        }
        footer={
          <div className='flex justify-end gap-3'>
            <Button
              variant='neutral'
              onClick={() => setNewEventModal(false)}
              leftIcon={<XMarkIcon />}
            >
              Tidak
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                createEvent()
              }}
              leftIcon={<DocumentCheckIcon />}
            >
              Simpan
            </Button>
          </div>
        }
      />
      <div>
          <DataViewBase name="Daftar Event" noun="event" ctaButton={ctaButton}>
              {events.length > 0 ? (
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
                      {events.map((event: any, key: string) => (
                          <EventCard data={event} key={key} mode="private" />
                      ))}
                  </div>
              ) : (
                  <div className="flex flex-col items-center gap-6 p-6">
                      <img src="/assets/empty.png" width={300} height={247} alt="No events available" />
                      <div className="flex flex-col items-center gap-3">
                          <p className="text-2xl font-medium">Belum ada event</p>
                          <p className="text-center text-base text-neutral-70">
                              Saat ini belum terdapat event yang telah dibuat.
                          </p>
                      </div>
                      <Button onClick={onNewEventClicked} leftIcon={<PlusIcon />}>
                          Buat Event
                      </Button>
                  </div>
              )}
          </DataViewBase>
      </div>
    </>
  );
}
