import { Modal } from "@/components/modal";
import { FormLabel } from "@/components/form/formlabel";
import { Input } from "@/components/form/input";
import { Textarea } from "@/components/form/textarea";
import { Button } from "@/components/button";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { DocumentCheckIcon } from "@heroicons/react/24/solid";
import { TicketList } from "@/routes/org/event/_ticket/tickets";
import React from "react";

interface TicketComponentProps {
    ticketModal: { state: boolean; label: string };
    closeModalTicket: () => void;
    errors: {
        name?: string;
        price?: string;
        quantity?: string;
        max_buy?: string;
        start_sell_at?: string;
        end_sell_at?: string;
        description?: string;
        tickets?: string;
    };
    ticketData: any;
    setData: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => void;
    updateTicket: () => void;
    tickets: object;
    onEditTicketClicked: (ticketId: string) => void;
    onAddTicketClicked: () => void;
    onDeleteTicketClicked: (ticketId: string) => void;
}

export const TicketComponent: React.FC<TicketComponentProps> = ({
                                                                    ticketModal,
                                                                    closeModalTicket,
                                                                    errors,
                                                                    ticketData,
                                                                    setData,
                                                                    updateTicket,
                                                                    tickets,
                                                                    onEditTicketClicked,
                                                                    onAddTicketClicked,
                                                                    onDeleteTicketClicked,
                                                                }) => {
    return (
        <>
            <Modal
                show={ticketModal.state}
                onRequestClose={closeModalTicket}
                title={ticketModal.label === "edit" ? "Edit tiket" : "Tambah tiket"}
                body={
                    <>
                        <div className="mb-3">
                            <FormLabel label="Nama tiket" errorText={errors.name} required>
                                <Input type="text" value={ticketData.name} onChange={(e) => setData(e, "name")} required />
                            </FormLabel>
                        </div>
                        <div className="mb-3 flex justify-stretch gap-2">
                            <FormLabel label="Harga tiket" errorText={errors.price} required>
                                <Input leftDeco="Rp" type="price" value={ticketData.price} onChange={(e) => setData(e, "price")} required />
                            </FormLabel>
                            <FormLabel label="Jumlah tiket" errorText={errors.quantity} required>
                                <Input type="number" min={0} value={ticketData.quantity} onChange={(e) => setData(e, "quantity")} required />
                            </FormLabel>
                            <FormLabel label="Maks. pembelian" errorText={errors.max_buy} required>
                                <Input type="number" min={0} value={ticketData.max_buy} onChange={(e) => setData(e, "max_buy")} required />
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label="Waktu mulai dijual" errorText={errors.start_sell_at} required>
                                <Input type="datetime-local" value={ticketData.start_sell_at} onChange={(e) => setData(e, "start_sell_at")} required />
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label="Waktu berakhir dijual" errorText={errors.end_sell_at} required>
                                <Input type="datetime-local" value={ticketData.end_sell_at} onChange={(e) => setData(e, "end_sell_at")} required />
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label="Deskripsi Tiket" errorText={errors.description}>
                                <Textarea value={ticketData.description} onChange={(e) => setData(e, "description")} required />
                            </FormLabel>
                        </div>
                    </>
                }
                footer={
                    <div className="flex justify-end gap-3">
                        <Button variant="neutral" onClick={closeModalTicket} leftIcon={<XMarkIcon />}>
                            Tutup
                        </Button>
                        <Button onClick={updateTicket} leftIcon={<DocumentCheckIcon />}>
                            Simpan
                        </Button>
                    </div>
                }
            />

            <TicketList
                data={tickets}
                title={<p className="flex-grow text-base font-medium text-danger">Daftar tiket</p>}
                onEditTicketClicked={onEditTicketClicked}
                onDeleteTicketClicked={onDeleteTicketClicked}
                button={
                    <Button size="sm" look="outline" onClick={onAddTicketClicked} leftIcon={<PlusIcon />}>
                        Tambah tiket baru
                    </Button>
                }
            />
            {errors.tickets && <p className="block text-xs text-danger">{errors.tickets}</p>}
        </>
    );
};
