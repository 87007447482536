import React, {useEffect, useState} from "react";
import {Input} from "@/components/form/input";
import {FormLabel} from "@/components/form/formlabel";
import {Button} from '@/components/button';
import {ArrowRightStartOnRectangleIcon, CheckIcon} from "@heroicons/react/24/solid";
import {useDispatch, useSelector} from "react-redux";
import {clearErrors, logout, setSuccessMsgEmail} from "@/reducers/auth"
import {AppDispatch} from "@/root/store";
import {GetCountries} from "@/services/helpers";
import {SendEmailVerification, VerifyCode} from "@/services/auth";

export function Complete() {
    const dispatch: AppDispatch = useDispatch();
    const [formData, setFormData] = useState({
        country_id: '',
        phone_code: '',
        phone: '',
        verification_code: '',
        email_verification_code: ''
    });
    const [countdown, setCountdown] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const {countries} = useSelector((state: any) => state.helpers);
    const {
        loading,
        errors,
        isLoggedOut,
        successMsg,
        successMsgEmail,
        isSuccess,
        isVerified
    } = useSelector((state: any) => state.auth);

    // @ts-ignore
    const user: any = JSON.parse(localStorage.getItem('user'));
    const email = user?.email;

    useEffect(() => {
        if (!user) {
            window.location.href = "/auth/login";
        }

        if (user?.isVerified) {
            window.location.href = "/";
        }
    }, [user])

    useEffect(() => {
        if (isLoggedOut) {
            window.location.href = '/auth/login';
        }
    }, [isLoggedOut]);

    useEffect(() => {
        dispatch(GetCountries())
    }, [dispatch])

    const setData = (e: any, element: string) => {
        setFormData({...formData, [element]: e.target.value})

        dispatch(successMsgEmail(''));
    }

    const startCountdown = () => {
        setCountdown(60);
        setIsButtonDisabled(true);

        const interval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown <= 1) {
                    clearInterval(interval);
                    setIsButtonDisabled(false);
                    dispatch(setSuccessMsgEmail(''));
                    return 0;
                }
                return prevCountdown - 1;
            });
        }, 1000);
    };

    const sendVerificationEmail = () => {
        dispatch(SendEmailVerification());
        startCountdown();
    }

    const submitVerificationCode = (e: any) => {
        e.preventDefault();

        dispatch(VerifyCode(formData))
    }

    useEffect(() => {
        if (isVerified) {
            window.location.href = "/"
        }
    }, [isVerified])

    return (
        <>
            <div className="text-900 text-3xl font-medium mb-1">Sedikit lagi 👋🏻</div>
            <p className={"text-400 mb-4"}>Tambahkan negara dan nomor hp kamu</p>
            <form onSubmit={submitVerificationCode}>
                <div className={'mt-12'}>
                    <FormLabel label='' errorText={errors.country_id} required>
                        <div className="grid grid-cols-3 gap-2 mb-3">
                            {
                                countries.map((item: any, index: string) => (
                                    <Button
                                        onClick={() => {
                                            setFormData({...formData, country_id: item.id, phone_code: item.phone_code})
                                        }}
                                        type={'button'}
                                        id={index}
                                        className={'w-full'}
                                        variant={formData.country_id === item.id ? 'primary' : 'neutral'}
                                        look={'fill'}
                                        leftIcon={<img src={item.flags} alt={item.name}/>}
                                    >{item.name}</Button>
                                ))
                            }
                        </div>
                    </FormLabel>
                    {!user?.email_verified_at && <div>
                        <div className="mb-3">
                            <FormLabel label='Email' errorText={errors.email} successText={successMsgEmail} required>
                                <Input type='email' readOnly disabled={!formData.country_id || isButtonDisabled} value={email} rightDeco={
                                    <span className={'cursor-pointer'} onClick={sendVerificationEmail}>
                                    {isButtonDisabled ? `${countdown}` : "Kirim Kode"}
                                </span>}
                                       placeholder={'organizer@gmail.com'} onChange={(e) => setData(e, 'email')}/>
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label='Kode Verifikasi Email' errorText={errors["type.email.code"]} required>
                                <Input type='number' min={0} onChange={(e) => setData(e, 'email_verification_code')}
                                       required/>
                            </FormLabel>
                        </div>
                        <div className="mb-3">
                            <FormLabel label='Whatsapp' errorText={errors.phone} required>
                                <Input leftDeco={formData.phone_code} value={formData.phone}
                                       placeholder={'81234567890'}
                                       onChange={(e) => setData(e, 'phone')} type='text' required/>
                            </FormLabel>
                        </div>
                    </div>}
                    <div className={'mt-12 flex flex-col gap-2'}>
                        <Button
                            disabled={!isSuccess}
                            loading={loading}
                            type={'submit'}
                            className={'w-full'}
                            color='neutral'
                            leftIcon={<CheckIcon/>}
                        >Verifikasi</Button>
                        <Button
                            loading={loading}
                            onClick={() => {
                                dispatch(logout())
                            }
                            }
                            type={'button'}
                            className={'w-full'}
                            variant={'danger'}
                            leftIcon={<ArrowRightStartOnRectangleIcon/>}
                        >Logout</Button>
                    </div>
                </div>
            </form>
        </>
    )
}
